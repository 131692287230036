<template>
  <div id="node">
    <div class="card mb-4">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-5">
          <div class="blobs-container" style="float:right;">
            <div class="blob" :class="mqtt_status"></div>
          </div>
          <i class="fas fa-tablet-alt"></i>
          Device Number {{device.number}}
        </strong>
        <hr>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <li><a @click="redirect('/member/mornitor')">สาขา</a></li>
            <li><a @click="redirect('/member/branch/'+node.b_id)">{{branch.name}}</a></li>
            <li><a @click="redirect('/member/node/'+device.n_id)">{{node.name}} <small class="ml-1 is-size-7">({{node.mac}})</small></a></li>
            <li class="is-active"><a>Device Number {{device.number}}</a></li>
          </ul>
        </nav>
        <hr>
      </div>
    </div>
    <div class="mb-4">
      <div class="columns is-multiline is-touch">
        <div class="column is-half">
          <div class="box">
            <div class="table-container">
              <table class="table is-bordered w-100">
                <thead>
                  <tr class="has-text-centered is-size-7">
                    <th>Phase</th>
                    <th>Volt</th>
                    <th>Current</th>
                    <th>Power</th>
                    <th>Power Factor</th>
                    <th>Frequency</th>
                  </tr>
                </thead>
                <tbody class="has-text-centered is-size-7">
                  <tr>
                    <td class="has-text-info"> <b>A</b> </td>
                    <td>{{latestLog.Volt_a}} V</td>
                    <td>{{latestLog.Current_a}} A</td>
                    <td>{{latestLog.Power_a}} W</td>
                    <td>{{latestLog.PF_a}} </td>
                    <td>{{latestLog.Freq}} Hz</td>
                  </tr>
                  <tr>
                    <td class="has-text-info"> <b>B</b> </td>
                    <td>{{latestLog.Volt_b}} V</td>
                    <td>{{latestLog.Current_b}} A</td>
                    <td>{{latestLog.Power_b}} W</td>
                    <td>{{latestLog.PF_b}} </td>
                    <td>{{latestLog.Freq}} Hz</td>
                  </tr>
                  <tr>
                    <td class="has-text-info"> <b>C</b> </td>
                    <td>{{latestLog.Volt_c}} V</td>
                    <td>{{latestLog.Current_c}} A</td>
                    <td>{{latestLog.Power_c}} W</td>
                    <td>{{latestLog.PF_c}} </td>
                    <td>{{latestLog.Freq}} Hz</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="box" style="height:165px">
            <div class="table-container">
              <table class="table is-bordered w-100">
                <thead>
                  <tr class="has-text-centered is-size-7">
                    <th>Total Power</th>
                    <th>Power Factor</th>
                    <!-- <th>Total Frequency</th> -->
                    <th>Total Energy</th>
                    <th>Img Energy</th>
                    <th>Exp Energy</th>
                  </tr>
                </thead>
                <tbody class="has-text-centered is-size-7">
                  <tr>
                    <td class="has-text-link"> <b>{{latestLog.Power_t}} W </b> </td>
                    <td> {{latestLog.PF_t}} </td>
                    <!-- <td> {{latestLog.Freq}} Hz </td> -->
                    <td class="has-text-primary"> {{Total_Energy}} Kwh </td>
                    <td> {{latestLog.Imp_Energy}} Kwh </td>
                    <td> {{latestLog.Exp_Energy}} Kwh </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4" v-if="renderChart">
      <div class="columns is-desktop">
        <div class="column is-full">
          <line-chart :chart-data="chartData" :extra-options="extraOptions" style="min-height:375px;"></line-chart>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import * as chartConfig from '@/components/Charts/chart.config'
import LineChart from '@/components/Charts/LineChart'

import moment from 'moment'

export default {
  name: 'Membernode',
  components:{ LineChart },
  props: {
    loaded: Object,
    branchs: Array,
    nodes: Array,
    devices: Array,
    device_log: Array,
    client: Object,
    connected: Boolean,
  },
  data(){
    return {
      d_id: this.$route.params.id,
      branch: {},
      node: {},
      device: {},
      message: [],
      dataCollection: null,
      renderChart: false,
      modal_device: false,
      extraOptions: chartConfig.chartOptionsMain
    }
  },
  created(){
    const INDEX = this.devices.map((d) => parseInt(d.id)).indexOf(parseInt(this.d_id));
    if(INDEX!==-1){
      this.device = this.devices[INDEX];

      const INDEX_N = this.nodes.map((n) => parseInt(n.id)).indexOf(parseInt(this.device.n_id));
      if(INDEX_N!==-1){
        this.node = this.nodes[INDEX_N];

        const INDEX_B = this.branchs.map((b) => parseInt(b.id)).indexOf(parseInt(this.node.b_id));
        this.branch = this.branchs[INDEX_B];
      }else{
        this.redirect("/member/mornitor/")
      }
    }else{
      this.redirect("/member/mornitor/")
    }
  },
  mounted(){
    if(!this.loaded.get_device_log){
      this.$emit('get_device_log',this.d_id);
    }else{
      const INDEX_ = this.loaded.get_device_log.map((d_id) => d_id).indexOf(this.d_id);
      if(INDEX_==-1){
        this.$emit('get_device_log',this.d_id);
      }
    }

    if(!parseInt(this.device.enable)){
      if(parseInt(this.device.id)){
        this.$swal({
          title: "อุปกรณ์นี้ถูกปิดใช้งาน!",
          text: "(Mac Address: "+this.node.mac+") \n (Device Number: "+this.device.number+")",
          className: "text-center",
          icon: "error",
        });
      }
    }else{
      if(this.client){
        this.$emit("subscribeMQTT",this.node.mac);
      }else{
        this.$emit("connectMQTT",this.node.mac);
      }
      this.checkRenderChart();
    }
  },
  methods:{
    checkRenderChart(){
      if(!this.renderChart && !this.thisDeviceLog.length){
        setTimeout(() => {
          this.checkRenderChart();
        }, 100);
        return false;
      }
      this.renderChart = true;
    },
    submit_edit_device(input){
      console.log(input);
    },
  },
  computed:{
    mqtt_status(){
      return (!this.connected) ? "red" : "green"
    },
    thisDeviceLog(){
      return this.device_log.filter((log) => {
        return parseInt(log.d_id)==parseInt(this.device.id)
      });
    },
    latestLog(){
      let count = this.thisDeviceLog.length;
      if(parseInt(count)){
        return this.thisDeviceLog[0];
      }else {
        return {}
      }
    },
    Total_Energy(){
      if(!this.latestLog.id){
        return 0;
      }else{
        const IMP_ENERGY = (this.latestLog.Imp_Energy!=="-") ? parseFloat(this.latestLog.Imp_Energy) : parseInt(0);
        const EXP_ENERGY = (this.latestLog.Exp_Energy!=="-") ? parseFloat(this.latestLog.Exp_Energy) : parseInt(0);
        return IMP_ENERGY + EXP_ENERGY;
      }
    },
    chartData(){
      let limit = 24;
      let count = 0;
      let LABELS = [];
      let POWER_A = [];
      let POWER_T = [];
      let LastTimeLabel = null;
      let ThreePhase = false;
      this.thisDeviceLog.forEach((e) => {
        if(count<=limit){
          let TimeLabel = moment(new Date(parseInt(e.date_now))).format('h:mm a');
          if(LastTimeLabel!==TimeLabel){
            LABELS.unshift(TimeLabel);
            LastTimeLabel = TimeLabel;
          }else{
            LABELS.unshift("");
          }

          if(e.Power_t!=="-")ThreePhase=true;
          POWER_A.unshift(e.Power_a);
          POWER_T.unshift(e.Power_t);
          count++;
        }
      });
      // .........................................................
      let CHART = {
        labels: LABELS,
        datasets: []
      };
      if(ThreePhase){
        CHART.datasets = [
          {
            label: "Total Power",
            borderColor: "#485fc7",
            pointBackgroundColor: "#485fc7",
            backgroundColor: "transparent",
            data: POWER_T
          }
        ]
      }else{
        CHART.datasets = [
          {
            label: "Power",
            borderColor: "#3e8ed0",
            pointBackgroundColor: "#3e8ed0",
            backgroundColor: "transparent",
            data: POWER_A
          }
        ]
      }
      return CHART
    }
  },
  // watch:{
  //   renderChart(val){
  //     if(!val)setTimeout(() => this.renderChart=true, 1);
  //   },
  // }
}
</script>

<style scoped="">
.blobs-container {
  display: flex;
}

.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 15px;
  width: 15px;
  margin-top: 5px;
  transform: scale(1);
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
</style>
